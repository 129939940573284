// import TextField from '@mui/material/TextField';
// import { useEffect, useState } from 'react';

// export default function CustomTextField({ value, setValue, placeholder, height = '3.2rem', disabled }: any) {
// 	const [text, setText] = useState<string>('')

// 	const sxTextFieldOptions = {
// 		width: '100%',
// 		height: height,
// 		borderWidth: '15px',
// 		backgroundColor: '#fff',

// 		borderRadius: '1rem',
// 		'.MuiOutlinedInput-notchedOutline': {
// 			border: 'none',
// 			borderRadius: '.6rem',
// 		},
// 		'&:hover .MuiOutlinedInput-notchedOutline': {
// 			border: 'none',
// 		},
// 		'.Mui-focused .MuiOutlinedInput-notchedOutline': {
// 			border: 'none',
// 		},
// 		'.MuiInputBase-input': {
// 			fontSize: '12px', // Ajusta o tamanho da fonte do texto dentro do TextField
// 		},
// 		'.MuiInputLabel-outlined': {
// 			fontSize: '13px', // Ajusta o tamanho da fonte da label quando não está "shrinked"
// 			opacity: .5,
// 		},
// 		'.MuiInputLabel-outlined.MuiInputLabel-shrink': {
// 			transform: 'translate(14px, 0px) scale(0.8)', // Ajusta a posição e escala da label quando "shrinked"
// 		},
// 	};

// 	useEffect(() => { setText(value) }, [value])

// 	return (
// 		<TextField
// 			label={placeholder}
// 			value={text}
// 			onChange={(event) => { setText(event.target.value); setValue(event.target.value) }}
// 			sx={sxTextFieldOptions}
// 			variant="outlined"
// 			disabled={disabled}
// 		/>
// 	);
// }

import TextField from '@mui/material/TextField';
import { useEffect, useState } from 'react';

export default function CustomTextField({ value, setValue, placeholder, height = '3.2rem', disabled, isDate = false, onlyNumbers = false }: any) {
	const [text, setText] = useState<string>('');

	const sxTextFieldOptions = {
		width: '100%',
		height: height,
		borderWidth: '15px',
		backgroundColor: '#fff',
		borderRadius: '1rem',
		'.MuiOutlinedInput-notchedOutline': {
			border: 'none',
			borderRadius: '.6rem',
		},
		'&:hover .MuiOutlinedInput-notchedOutline': {
			border: 'none',
		},
		'.Mui-focused .MuiOutlinedInput-notchedOutline': {
			border: 'none',
		},
		'.MuiInputBase-input': {
			fontSize: '12px',
		},
		'.MuiInputLabel-outlined': {
			fontSize: '13px',
			opacity: 0.5,
		},
		'.MuiInputLabel-outlined.MuiInputLabel-shrink': {
			transform: 'translate(14px, 0px) scale(0.8)',
		},
	};

	useEffect(() => {
		setText(value);
	}, [value]);
	

	const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
		let inputValue = event.target.value;

		if (onlyNumbers) {
			// Permitir apenas números e o caractere ponto (.)
			inputValue = inputValue.replace(/[^0-9.]/g, '');
		}

		if (isDate) {
			// Remove caracteres não numéricos
			inputValue = inputValue.replace(/\D/g, '');
			if (inputValue.length > 2 && inputValue.length <= 4) {
				inputValue = `${inputValue.slice(0, 2)}/${inputValue.slice(2)}`;
			} else if (inputValue.length > 4) {
				inputValue = `${inputValue.slice(0, 2)}/${inputValue.slice(2, 4)}/${inputValue.slice(4, 8)}`;
			}
		}

		setText(inputValue);
		setValue(inputValue);
	};

	return (
		<TextField
			label={placeholder}
			value={text}
			onChange={handleInputChange}
			sx={sxTextFieldOptions}
			variant="outlined"
			disabled={disabled}
		/>
	);
}
