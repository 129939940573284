import React from 'react';
import './styles.css';

interface SwitchButtonProps {
  isOn: boolean;
  onToggle: () => void;
}

const SwitchButton: React.FC<SwitchButtonProps> = ({ isOn, onToggle }) => {
  return (
    <label className="switch">
      <input
        type="checkbox"
        checked={isOn}
        onChange={onToggle}
        className="switch-input"
      />
      <span className={`switch-slider ${isOn ? 'on' : ''}`} />
    </label>
  );
};

export default SwitchButton;
