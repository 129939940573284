import { faAngleLeft } from "@fortawesome/free-solid-svg-icons";
import FlagSelector from "../../../../../components/FlagSelector";
import { CustomModalPage } from "../../../../../components/Modal";
import Sidebar from "../../../../../components/Sidebar";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import CustomDropBox from "../../../../../components/CustomDropBox";
import CustomTextField from "../../../../../components/CustomTextField";
import { useGlobalContext } from "../../../../../contexts/GlobalContext";
import lang from '../../lang'
import { useEffect, useState } from "react";

type SecondPageProps = {
    selectedEquipment: any;
    technicalData: any;
    setTechnicalData: (techData: any) => void;
    edit: boolean;
    modalOpen: boolean;
    setPage: (pageNum: number) => void;
}

export function SecondPage({ 
    technicalData, 
    setTechnicalData, 
    edit, 
    modalOpen,
    selectedEquipment,
    setPage
}: SecondPageProps) {
    const [canGoToNextPage, setCanGoToNextPage] = useState(false)
    const [fieldChanged, setFieldChanged] = useState(false)
    const [units, setUnits] = useState<{ [key: string]: string }>({});
    const [values, setValues] = useState<{ [key: string]: number }>({});
    const [fClass, setFClass] = useState<string>('')
    const [nrCategory, setNRCategory] = useState<string>('')

    const { globalOptions } = useGlobalContext()
    const currLang = globalOptions.language

    const pressureUnits = [
        { name: "Pa", id: 0 },
        { name: "kPa", id: 1 },
        { name: "MPa", id: 2 },
        { name: "kgf/cm²", id: 3 },
        { name: "lbf/in²", id: 4 },
        { name: "mmHg 0ºC", id: 5 },
        { name: "inHg 0ºC", id: 6 },
        { name: "mmH2O 4ºC", id: 7 },
        { name: "mH2O 4ºC", id: 8 },
        { name: "inH2O 20º", id: 9 },
        { name: "bar", id: 10 },
        { name: "mbar", id: 11 },
        { name: "cmHg 0ºC", id: 12 },
        { name: "Torr", id: 13 },
        { name: "PSI", id: 14 },
        { name: "lb/ft²", id: 15 },
        { name: "cmH2O 4ºC", id: 16 },
        { name: "ftH2O 20º", id: 17 },
        { name: "ATM", id: 18 },
        { name: "MSW", id: 19 },
        { name: "hPa", id: 20 },
        { name: "mHg 0ºC", id: 21 },
        { name: "inSW", id: 22 },
        { name: "ftSW", id: 23 }
    ]

    const temperatureUnits = [
        { name: "°C", id: 0 },
        { name: "°F", id: 1 },
        { name: "K", id: 2 },
        { name: "°R", id: 3 },
        { name: "°Re", id: 4 }
    ]

    const volumeUnits = [
        { name: "L", id: 0 },
        { name: "mL", id: 1 },
        { name: "m³", id: 2 },
        { name: "cm³", id: 3 },
        { name: "mm³", id: 4 },
        { name: "ft³", id: 5 },
        { name: "in³", id: 6 },
        { name: "US gal", id: 7 },
        { name: "Imp gal", id: 8 },
        { name: "bbl", id: 9 },
        { name: "US qt", id: 10 },
        { name: "US pt", id: 11 },
        { name: "US fl oz", id: 12 },
        { name: "Imp fl oz", id: 13 },
        { name: "mm³", id: 14 }
    ]

    const pressureTable: { [unit: string]: number } = {
        "Pa": 1e-6,
        "kPa": 0.001,
        "MPa": 1,
        "kgf/cm²": 0.0981,
        "lbf/in²": 0.0068947,
        "mmHg 0ºC": 0.000133322,
        "inHg 0ºC": 0.00338637,
        "mmH2O 4ºC": 0.00000980637841,
        "mH2O 4ºC": 0.000098067,
        "inH2O 20º": 0.00024864485,
        "bar": 0.1,
        "mbar": 0.0001,
        "cmHg 0ºC": 0.01333218,
        "Torr": 0.000133322,
        "PSI": 0.0068948,
        "lb/ft²": 0.00004788,
        "cmH2O 4ºC": 0.0000980634,
        "ftH2O 20º": 0.00298694,
        "ATM": 0.101325,
        "MSW": 0.00980665,
        "hPa": 0.0001,
        "mHg 0ºC": 0.133322,
        "inSW": 0.000256804,
        "ftSW": 0.0030682,
    };    

    const volumeTable: { [unit: string]: number } = {
        "L": 0.001,
        "mL": 1e-6,
        "m³": 1,
        "cm³": 1e-6,
        "mm³": 1e-9,
        "ft³": 0.0283168,
        "in³": 1.63871e-5,
        "US gal": 0.00378541,
        "Imp gal": 0.00454609,
        "bbl": 0.158987,
        "US qt": 0.000946353,
        "US pt": 0.000473176,
        "US fl oz": 2.9574e-5,
        "Imp fl oz": 2.84131e-5,
    };

    const categoryMap: { [key: string]: { [key: number]: string } } = {
        "A": { 1: "I", 2: "I", 3: "II", 4: "III", 5: "III" },
        "B": { 1: "I", 2: "II", 3: "III", 4: "IV", 5: "IV" },
        "C": { 1: "I", 2: "II", 3: "III", 4: "IV", 5: "V" },
        "D": { 1: "II", 2: "III", 3: "IV", 4: "V", 5: "V" }
    }

    useEffect(() => { 
        const isValid = Object.values(technicalData).every(val => val && val !== undefined && val !== '')
        setCanGoToNextPage(isValid)
    }, [fieldChanged])
    
    function handleTechnicalDataChange(keyName: string, value: any, isId: boolean=false) {
		const newItem = technicalData
        const params = JSON.parse(selectedEquipment.equipment_type.parameters)

        for (let lang in newItem) {
            // Caso seja uma lista de opções, coloca o valor recebido correspondente (pelo id) em cada língua
            if (isId){
                newItem[lang][keyName]['value'] = params[lang][keyName]['info'].find((i: any) => i.id === value).name
                if (keyName === 'fluidClass')
                    setFClass(newItem[lang][keyName]['value'])
            } else
                newItem[lang][keyName]['value'] = value
        }

		setTechnicalData(newItem)
        setFieldChanged(!fieldChanged)
	}

    function handleUnitChange(keyName: string, unit: any) {
        const newItem = technicalData
        const newUnits = { ...units }
        const newValues = { ...values }

        for (let lang in newItem) {
            if (newItem[lang][keyName]) {

                if (newUnits[keyName]) {
                    newItem[lang][keyName]['value'] = newItem[lang][keyName]['value'].replace(` ${newUnits[keyName]}`, '')
                }
                if (keyName === 'operatingPressure' || keyName === 'volume')
                    newValues[keyName] = newItem[lang][keyName]['value']
                newItem[lang][keyName]['value'] += ` ${unit.name}`
            }
        }
    
        newUnits[keyName] = unit.name;
         

        setUnits(newUnits);
        setTechnicalData(newItem);
        setFieldChanged(!fieldChanged);
        setValues(newValues)
    }

    useEffect(() => {
        if (units.volume && units.operatingPressure) {
            handleConvert()
        }
      }, [units.volume, units.operatingPressure, fClass]);

    function handleConvert() {
        let nrValue

        let pFactor = pressureTable[units.operatingPressure]
        let vFactor = volumeTable[units.volume]
        
        let pConverted = pFactor * values.operatingPressure
        let vConverted = vFactor * values.volume

        nrValue = pConverted * vConverted

        handleCategory(nrValue)
    }

    function handleCategory(nrValue: number) {
        let group: number = 0

        if (nrValue > 100) {
            group = 1
        } else if (nrValue <= 100 && nrValue > 30) {
            group = 2
        } else if (nrValue <= 30 && nrValue > 2.5) {
            group = 3
        } else if (nrValue <= 2.5 && nrValue > 1) {
            group = 4
        } else if (nrValue <= 1) {
            group = 5
        }

        handleTechnicalDataChange('group', group)

        let category = categoryMap[fClass]?.[group];

        if (!category)
            alert("Classe do fluido ou grupo inválido");
        else {
            setNRCategory(category)
            handleTechnicalDataChange('nr13Classification', category)
        }
    }
    
    function renderInputs(equipmentType: any) {
		const parameters = (JSON.parse(equipmentType.parameters))[currLang]
		const sanitizedParams = Object.keys(parameters).map((key: string) => {
			const param = parameters[key]
			if (param.options) {
				const sanitizedInfo = param.info.map((i: any) => ({ name: i.name, id: i.id }))
				return { ...param, info: sanitizedInfo, keyName: key }
			}
			return { ...param, keyName: key }
		})

		return (
			<div style={{ display: 'flex', flexDirection: 'column', width: '100%', justifyContent: 'center', alignItems: 'center' }}>
				{
					sanitizedParams.map((param: any) => {
						if (param.options) {
							return (
								<div key={param.name} className='input-container-equipment' >
									<CustomDropBox
										options={param.info}
										placeholder={param.name}
										key={param.name}
										value={technicalData[currLang][param.keyName]['value']}
										setValue={(value: any) => handleTechnicalDataChange(param.keyName, value.id, true)}
									/>
									<div style={{ padding: '.6rem' }} />
								</div>
							)
						}
						else {
                            if (param.info === 'pressure' || param.info === 'temperature' || param.info === 'volume'){
                                return (
                                    <div key={param.name} className='input-container-equipment'>
                                        <div style={{display: 'flex', justifyContent: 'space-between'}}>
                                            <div style={{width: '69%'}}>
                                                <CustomTextField
                                                    placeholder={param.name}
                                                    value={technicalData[currLang][param.keyName]['value'] && technicalData[currLang][param.keyName]['value'].includes('undefined') ? '' : technicalData[currLang][param.keyName]['value']}
                                                    setValue={(value: any) => handleTechnicalDataChange(param.keyName, value)}
                                                    onlyNumbers={true}
                                                />
                                            </div>

                                            <div style={{ width: '30%' }}>
                                                <CustomDropBox
                                                    options={param.info === 'pressure' ? pressureUnits : param.info === 'temperature' ? temperatureUnits : volumeUnits}
                                                    placeholder={lang.units[currLang]}
                                                    key={param.name}
                                                    value={units[param.keyName] || ''}
                                                    setValue={(unit: any) => handleUnitChange(param.keyName, unit)}
                                                    disabled={technicalData[currLang][param.keyName]['value'] === null} 
                                                />
                                            </div>
                                        </div>
                                        
                                        <div style={{ padding: '.6rem' }} />
                                    </div>
                                )
                            } else if (param.info === 'date') {
                                return (
                                    <div key={param.name} className='input-container-equipment'>
                                        <CustomTextField
                                            placeholder={param.name}
                                            value={technicalData[currLang][param.keyName]['value']}
                                            setValue={(value: any) => handleTechnicalDataChange(param.keyName, value)}
                                            isDate={true}
                                        />
                                        <div style={{ padding: '.6rem' }} />
                                    </div>
                                );
                            } else {
                                return (
                                    <div key={param.name} className='input-container-equipment'>
                                        <CustomTextField
                                            placeholder={param.name}
                                            value={technicalData[currLang][param.keyName]['value']}
                                            setValue={(value: any) => handleTechnicalDataChange(param.keyName, value)}
                                            disabled={param.info === 'nr13'}
                                        />
                                        <div style={{ padding: '.6rem' }} />
                                    </div>
                                );
                            }
						}
					})
				}
			</div>
		);
	}

    return (
        <div className="MainContainer">
            <CustomModalPage
                title={lang.equipmentRegistered[currLang]}
                subtitle={lang.wantToRequestInspection[currLang]}
                yesPage='/Request?insert'
                noPage='/Home'
                yesText={lang.yes[currLang]}
				noText={lang.no[currLang]}
                modal={modalOpen}
            />

            <div className='SideBarCompensation' />

            <div className='CentralBlock' style={{ height: '100vh' }}>
                <FlagSelector />
                <Sidebar activePage='Register' />

                <div className="RegisterMainContainer">
                    <div style={{ padding: '.7rem' }} />

                    <div className="iconContainer" style={{ width: '100%' }}>
                        <div
                            className='iconLeft' style={{ alignSelf: 'center' }}
                            onClick={() => setPage(1)}
                        >
                            <FontAwesomeIcon icon={faAngleLeft} title="Empresas já cadastradas" />
                        </div>

                        <div style={{ padding: '10px' }} />

                        <div className='clientText'>{edit ? lang.editEquipment[currLang] : lang.registerEquipment[currLang]}</div>

                        <div style={{ flex: 1 }} />
                    </div>

                    <div style={{ padding: '.7rem' }} />

                    <div className='text-equipment2'>{lang.thecnicData[currLang]}</div>

                    {renderInputs(selectedEquipment.equipment_type)}
                    <div style={{ display: 'flex', flexDirection: 'column', alignSelf: 'center', width: '85%' }}>
                        <button
                            className='button-equipment-new'
                        onClick={() => setPage(3)}
                            style={!canGoToNextPage ? { opacity: 0.8, cursor: 'not-allowed' } : {}}
                            disabled={!canGoToNextPage}
                        >
                            {lang.next[currLang]}
                        </button>
                    </div>


                </div>
            </div>
        </div>
    )
}