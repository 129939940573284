import { ChangeEvent, useEffect, useRef, useState } from 'react';
import { faAngleLeft, faCheckSquare, faFolder, faLayerGroup, faRectangleAd, faSquare, faCheck, faCopy } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import mapboxgl from 'mapbox-gl';
import {  Await, useNavigate } from 'react-router-dom';
import axios from 'axios';
import { writeFile } from 'fs';

import './styles.css';



import Sidebar from '../../../components/Sidebar';
import FlagSelector from '../../../components/FlagSelector';
import FileUpload from '../../../components/FileUpload';
import DropDown, { DropDownRef } from '../../../components/DropDown';
import { useRegister, RegisterAction} from '../../../contexts/RegisterContext';
import { getStoredJson, randomNumericString, saveJsonData, sleep, useInitialCheck } from '../../../utils';
import { useGlobalContext } from '../../../contexts/GlobalContext';
import { addDataBaseRow, editDatabaseByCondition, editDatabaseRowsByColumn, genUUID, getDataBaseCondition, getDatabaseRows, getImagePaths, postApiData, sendFileDatabase, sendImageDatabase, url } from '../../../utils/database';
import CustomModal, { CustomModalPage } from '../../../components/Modal';
import CustomDropBox from '../../../components/CustomDropBox';
import CustomTextField from '../../../components/CustomTextField';
import { FileDocumentUpload } from '../../../components/FileUploadDocument';
import lang from './lang';
import AsyncButton from '../../../components/AsyncButton';
import validator from 'validator';

mapboxgl.accessToken = "pk.eyJ1Ijoia2xhdXNzbWFyY2hpIiwiYSI6ImNsbGNsb245dzAyNXkzbm9iZzJ4emt2eWsifQ.HznsI_vVJ-lWe9swvbfT-Q";



export function ProcessPage() {
  const {globalOptions, setGlobalOptions} = useGlobalContext()
  const currLang = globalOptions.language

  const [loading, setLoading] = useState<boolean>(false)
  const [modalOpen, setModalOpen] = useState<boolean>(false)
  const [fileData, setFileData] = useState<any>([])
  const [imageData, setImageData] = useState<any>([])

  const [numFloors, setNumFloors] = useState<number>(1)
  const [multipleLayers, setMultipleLayers] = useState<boolean>(false)

  const [selectedProcess, setSelectedProcess] = useState<any>({})
  const [companies, setCompanies] = useState([])
  const [plants, setPlants] = useState([])
  const navigate = useNavigate();

  const [expectedCode, setExpectedCode] = useState<any>({code: '', timestamp: null})
  const [nextModalOpen, setNextModalOpen] = useState(false)
	const [emailModalOpen, setEmailModalOpen] = useState(false)
  const [email, setEmail] = useState<string>('')
	const [emailMessage, setEmailMessage] = useState<string>('')
  const [emailWarning, setEmailWarning] = useState(false)
	const [formLink, setFormLink] = useState<string>('')
  const [formInfo, setFormInfo] = useState<any>({})


  const [mapStyle, setMapStyle] = useState<string>(localStorage.getItem('mapStyle') || "mapbox://styles/mapbox/streets-v12");
  const [edit, setEdit] = useState(false)
  const [copy, setCopy] = useState(false)
  const user = getStoredJson('user')
  useInitialCheck(importCompanies)
  useInitialCheck(importPlants, [selectedProcess.company])
  useInitialCheck(initialCheck, [window.location.href])

	const mapContainer = useRef<HTMLDivElement | null>(null);
  const [map, setMap] = useState<mapboxgl.Map | null>(null);
  const [marker, setMarker] = useState<mapboxgl.Marker | null>(null);
  const [hasMarker, setHasMarker] = useState(false)

  const [markerCoords, setMarkerCoords] = useState({ lng: 0, lat: 0 }); // Novo estado para coordenadas do marcador
  const [capturedImageUrl, setCapturedImageUrl] = useState("");
  const [page, setPage] = useState(1);
  const [showMap, setShowMap] = useState(true); // Estado inicial para mostrar o mapa

  
  useEffect(() => {
		localStorage.setItem('mapStyle', mapStyle);
	  }, [mapStyle]);

  async function importCompanies() {
		let data = []

		if(user.company == '123456789/0001')
			data = await getDatabaseRows('companies')
		else
			data = await getDataBaseCondition('companies', 'cnpj', user.company)

		setCompanies(data)
	}

  async function importPlants(){
    const company = selectedProcess.company
    const response = await getDataBaseCondition('plants', 'company', company ? company.cnpj : '???')
    setPlants(response)
  }
  
  async function initialCheck(){
    const currentUrl = window.location.href
    const isEdit = currentUrl.indexOf('?edit') != -1 
    const isInsert = currentUrl.indexOf('?insert') != -1
    const isModal: boolean = currentUrl.indexOf('?modal') != -1
    setEdit(isEdit)
    let response, data

    if (isModal)
			handleOpenNextModal()

    if(!isEdit && !isInsert)
      return

    data = getStoredJson(isEdit ? 'edit' : 'insert')

    if(data.company){
      response = await getDataBaseCondition('companies', 'cnpj', data.company)
      data.company = response[0]
    }

    if(data.plant){
      response = await getDataBaseCondition('plants', 'plant_id', data.plant)
      data.plant = response[0]
    }

    if(data.floor)
      setNumFloors(data.floor)

    setImageData(await getImagePaths(data.img_path))
    setSelectedProcess(data)
  }

  async function handleEdit(){
    const edit = getStoredJson('edit')

    const newData = {
      process_id: edit.process_id,
      name: selectedProcess.name,
      company: selectedProcess.company.cnpj,
      plant: selectedProcess.plant.plant_id,
      img_path: `img/process/${selectedProcess.process_id}`,
			doc_path: `doc/process/${selectedProcess.plant_id}`,
      lat: markerCoords.lat,
      long: markerCoords.lng,
      floor: numFloors
    }

    let response = await editDatabaseByCondition('processes', 'process_id', edit.process_id, newData)
 
    if(response.status != 'success')
      return alert('erro ao se comunicar com o servidor') 

    if(edit.company != newData.company)
      response = await editDatabaseRowsByColumn('equipments', 'company', edit.company, newData.company)

    if(edit.plant != newData.plant)
      response = await editDatabaseRowsByColumn('equipments', 'plant', edit.plant, newData.plant)

    for (let file of fileData)
			await sendFileDatabase(file, newData.doc_path)

		for (let x=0; x<imageData.length; x++){
			const file = imageData[x]
			await sendImageDatabase(file, newData.process_id, 'process', x)
		}

    navigate(-1)
  }

  const handleOpenNextModal = () => {
		setEmailModalOpen(false)
		setNextModalOpen(true)
	};

  function handleCopy() {
		setCopy(true)
		navigator.clipboard.writeText(formLink)
	}

  function isModalFieldsEmpty() {
		if (!email){
			console.log("n tem email");
			return true
		}
		for (let value of email) {
			if (!value || value.trim().length === 0) {
				console.log("Campo de email vazio ou contém apenas espaços em branco");
				return true;
			}
		}
	
		return false;
	}

  function handleManagement(){
    setGlobalOptions({...globalOptions, activeButton: 'Process'})
    navigate('/Management')
  }
  
  function handleNameChange(text: string){
    setSelectedProcess({...selectedProcess, name: text})
  }
  
  function handleCompanyChange(selectedOption: any){
    if(selectedOption.key && selectedOption.key == 'default')
      return navigate('/Company?insert')

    setSelectedProcess({...selectedProcess, company: selectedOption, plant: null})
  }

  function handlePlantChange(selectedOption: any){
    if(selectedOption.key && selectedOption.key == 'default'){
      saveJsonData('insert', {company: selectedProcess.company.cnpj})
      return navigate('/Plant?insert')
    }

    setSelectedProcess({...selectedProcess, plant: selectedOption})
  }

  const handleEmailChange = (newEmail: any) => {
		setEmail(newEmail); 
		setEmailWarning(false);
	};

  async function handleSendMail(){
    if(!validator.isEmail(email))
			return setEmailWarning(true)

		setLoading(true)
		await sleep(1000)
		
		let msg

		if (emailMessage != lang.emailMsg[currLang])
			msg = emailMessage
		else
			msg = lang.emailMsg[currLang]
		

		const newCode = randomNumericString(4)
		setExpectedCode({code: newCode, timestamp: new Date()})

		const data = {
			destinyMail: email.trim(),
			subject: lang.formFillingRequest[currLang],
			message: msg + '\n\n' + formLink
		}

		const response = await postApiData(data, 'sendMail')

		if(!response || response.status != 'success'){
			await handleSendMail()
			return
		}

		handleOpenNextModal()

		//setAlertTitle(lang.min[currLang])
		setLoading(false)
	}

  useEffect(() => {
    if (formInfo && formInfo.id) {
        setFormLink(formInfo.id);
    }
}, [formInfo]); 

  async function handleSubmit(){
    const uuid = genUUID()

    const processData = {
      process_id: uuid, 
      name: selectedProcess.name,
      company: selectedProcess.company.cnpj,
      plant: selectedProcess.plant.plant_id,
      processImage: selectedProcess.processImage,
      img_path: `img/process/${uuid}`,
			doc_path: `doc/process/${uuid}`,
      lat: markerCoords.lat,
      long: markerCoords.lng,
      floor: numFloors
    }

    const formData = {
			company: processData.company,
			asset_id: processData.process_id
		}

    const response = await addDataBaseRow('processes', processData)
    const response2 = await addDataBaseRow('forms', formData)

    if (response.status != 'success' || response2.status != 'success')
			return alert('erro ao adicionar')

    for (let file of fileData)
			await sendFileDatabase(file, processData.doc_path)

		for (let x=0; x<imageData.length; x++){
			const file = imageData[x]
      
      if(imageData[x].name == 'locality.png'){
        await sendImageDatabase(file, uuid, 'process', x, 'locality')
      }
			else{
        await sendImageDatabase(file, uuid, 'process', x)
      }  
		}

    saveJsonData('insert', {process: processData.process_id, plant: processData.plant, company: processData.company})
    
    await sleep(200)

		let emailMsg = lang.emailMsg[currLang]
		setEmailMessage(emailMsg)
		let formLink = url.replace('api/?', '') + 'Forms?process-' + uuid

    setFormLink(String(formLink))
		setEmailModalOpen(true)
  }

  function isFieldsEmpty(){
    const data = [
      selectedProcess?.name,
      selectedProcess?.company?.name,
      selectedProcess?.plant?.name
    ]

    for(let value of data){
      if(!value)
        return true

      if(value.trim().length == 0)
        return true
    }

    return false
  }

  function isMarkerSet(){
    //alert(marker)
    if(!marker)
        return false
    return true
  }

  useEffect(() => {

    if(page == 2){
    const initializeMap = () => {

      const map = new mapboxgl.Map({
        container: mapContainer.current!, // Especifica o elemento DOM para inicializar o mapa
        //style: 'mapbox://styles/mapbox/streets-v12', // O estilo do mapa
        style: mapStyle,
        center: [ selectedProcess.plant.long, selectedProcess.plant.lat], // Coordenadas iniciais do centro do mapa
        zoom: 18, // Zoom inicial
      });
    
    
      map.addControl(new mapboxgl.NavigationControl(), 'top-right');
      
      map.on('load', () => {
        setMap(map);
    });
    let newMarker: any = null
    //alert(marker)
    // Adiciona o evento de clique para adicionar marcadores
    map.on('click', function (e) {
      if(!newMarker) {
        newMarker = new mapboxgl.Marker({ draggable: false, color: 'yellow' })
          .setLngLat([e.lngLat.lng, e.lngLat.lat])
          .addTo(map)
        newMarker.getElement().addEventListener('click', (event: any) => {
          event.stopPropagation(); // Impede que o mapa capture este clique
          newMarker.remove();
          newMarker = null
          setMarkerCoords({lat: 0, lng: 0})
          setMarker(null); // Reseta o estado do marcador
        });
        setMarkerCoords({lat: e.lngLat.lat, lng: e.lngLat.lng})
        setMarker(newMarker); 
      }
    }
  );
  
    return () => map.remove(); // Remove o mapa ao desmontar o componente
  }

  if (!map) 
    initializeMap();

  }}, [map, marker, page]);

  async function downloadImage(url: RequestInfo | URL, filename: string) {
    try {
      const response = await fetch(url);
      if (!response.ok) throw new Error('Falha ao baixar a imagem.');
      const imageBlob = await response.blob(); // Obtém a resposta como Blob
      const imageFile = new File([imageBlob], filename, { type: imageBlob.type }); // Converte Blob para File
      return imageFile;
    } catch (error) {
      console.error('Erro ao baixar a imagem:', error);
      return null;
    }
  }
  
  async function captureMap() {
    let url
    if(mapStyle == "mapbox://styles/mapbox/streets-v12") 
      url = `https://api.mapbox.com/styles/v1/mapbox/streets-v12/static/geojson(%7B%22type%22%3A%22FeatureCollection%22%2C%22features%22%3A%5B%7B%22type%22%3A%22Feature%22%2C%22properties%22%3A%7B%22marker-color%22%3A%22%23FFFF00%22%7D%2C%22geometry%22%3A%7B%22type%22%3A%22Point%22%2C%22coordinates%22%3A%5B${markerCoords.lng}%2C${markerCoords.lat}%5D%7D%7D%5D%7D)/${markerCoords.lng},${markerCoords.lat},17/500x300?access_token=pk.eyJ1IjoiamVhbnZyMDk4IiwiYSI6ImNscGQyc2hnejBwaTcya21tY2w3emJpOHAifQ.Aa96RubapYbo0gdZbBUxjw`;
    else if (mapStyle == "mapbox://styles/mapbox/satellite-streets-v12")
      url = `https://api.mapbox.com/styles/v1/mapbox/satellite-streets-v12/static/geojson(%7B%22type%22%3A%22FeatureCollection%22%2C%22features%22%3A%5B%7B%22type%22%3A%22Feature%22%2C%22properties%22%3A%7B%22marker-color%22%3A%22%23FFFF00%22%7D%2C%22geometry%22%3A%7B%22type%22%3A%22Point%22%2C%22coordinates%22%3A%5B${markerCoords.lng}%2C${markerCoords.lat}%5D%7D%7D%5D%7D)/${markerCoords.lng},${markerCoords.lat},17/500x300?access_token=pk.eyJ1IjoiamVhbnZyMDk4IiwiYSI6ImNscGQyc2hnejBwaTcya21tY2w3emJpOHAifQ.Aa96RubapYbo0gdZbBUxjw`;
    
    if(url){
      setCapturedImageUrl(url); 
      setShowMap(false);
  
      const filename = "locality.png"; // Defina um nome de arquivo apropriado
  
      downloadImage(url, filename).then(imageFile => {
      if (imageFile) {
        console.log('Nome do arquivo:', imageFile.name);
        setImageData([...imageData, imageFile])
  
      }
      });
    }
  }
    
  function handleBack (){
    setPage(1)
    setMap(null)
    setShowMap(true)
    setMarker(null)
  };
  
  function firstPage (){
    return (
    <div className="MainContainer">
      <CustomModal modalOpen={emailModalOpen} className='emailModalContainer'>
				<div className='textsModalContainer'>
					<h1 className='PageModalTextContainer'>{lang.formRegister[currLang]}</h1>
				</div>
				<div className='textsModalContainer'>
					<p className='PageModalTextContainer'>{lang.formMessage[currLang]}</p>
				</div>
				<div className='modalInputText'>
					<CustomTextField
						placeholder={'Email'}
						value={email}
						setValue={handleEmailChange}
					/>
				</div>
				<div className='modalInputText'>	
					<CustomTextField
						placeholder={'Mensagem'}
						value={emailMessage}
						setValue={setEmailMessage}
					/>
				</div>
				<div className='modalInputText'>
					<CustomTextField
						placeholder={'Link'}
						value={formLink}
						disabled={true}
						//navigator.clipboard.writeText(selectedPlant.name)
					/>
					<FontAwesomeIcon 
						className={copy ? 'copyIconModalDisabled' : 'copyIconModal'}
						icon={copy ? faCheck : faCopy} 
						title="Copiar para área de transferência" 
						onClick={() => copy ? undefined : handleCopy()} 
					/>
				</div>
				
				<div className='modalButtonWrapper'>
					<AsyncButton
						active={loading || isModalFieldsEmpty()}
						text='Enviar'
						className='PageModalButtonContainer'
						onClick={handleSendMail}
						loading={loading}
					/>

					<AsyncButton
						active={loading}
						text='Fechar'
						className='PageModalButtonContainer'
						onClick={handleOpenNextModal}
						loading={loading}
					/>
				</div>
			</CustomModal>

      <CustomModalPage
				title={lang.processRegistered[currLang]}
				subtitle={lang.wantToRegisterEquipment[currLang]}
				yesPage='/Equipment?insert'
				noPage='/Home'
        yesText={lang.yes[currLang]}
				noText={lang.no[currLang]}
				modal={nextModalOpen}
			/>

      <div className='SideBarCompensation'/>

      <div className="CentralBlock" style={{height: '100vh'}}>
        <FlagSelector/>
        <Sidebar activePage='Register'/>

        <div className="RegisterMainContainer" >
          <div className="iconContainer" style={{width: '100%'}}>
              <div
                className='iconLeft' 
                style={{alignSelf: 'center', width: '7%'}}
                onClick={() => navigate(-1)}
                >
                
                <FontAwesomeIcon icon={faAngleLeft} title="Empresas já cadastradas"/> 
              </div>

              <div style={{display: 'flex', justifyContent: 'space-between', width: '85%'}}>
                <p className='clientText'>
                  {edit ? lang.editProcess[currLang] : lang.registerProcess[currLang]}
                </p>

                <div className='folderContainer' onClick={handleManagement}>
                  <FontAwesomeIcon icon={faFolder} title="Processos já cadastrados"/>        
                </div>
              </div>
          </div>

          <div style={{width: '85%'}}>
            <text className='equipmentText2'>{lang.processInfo[currLang]}</text>
          </div>

          <div style={{padding: '.6rem'}}/>

          <div style={{width: '85%'}}>
            <CustomTextField 
              placeholder={lang.name[currLang]}
              value={selectedProcess.name} 
              setValue={handleNameChange}
            />
          </div>
          
          <div style={{padding: '.6rem'}}/>

          <div style={{display: 'flex', flexDirection: 'column', alignSelf: 'center', width: '85%'}}>
            <CustomDropBox
              options={companies}
              value={selectedProcess.company ? selectedProcess.company.name : ''}
              setValue={handleCompanyChange}
              placeholder={lang.company[currLang]}
              key='name'
              callbackKey={lang.addNew[currLang]}
            /> 
          </div>

          <div style={{padding: '.6rem'}}/>

          <div style={{display: 'flex', flexDirection: 'column', alignSelf: 'center', width: '85%'}}>
            <CustomDropBox
              options={plants}
              value={selectedProcess.plant ? selectedProcess.plant.name : ''}
              setValue={handlePlantChange}
              placeholder={lang.plant[currLang]}
              key='name'
              callbackKey={lang.addNew[currLang]}
            />
          </div>

          <div style={{display: 'flex', flexDirection: 'column', alignSelf: 'center', width: '85%'}}>
            <FileDocumentUpload 
              fileData={fileData} 
              setFileData={setFileData} 
              title={lang.documents[currLang]}
            />
          </div>
        
          <div style={{display: 'flex', flexDirection: 'column', alignSelf: 'center', width: '85%'}}>
            <FileUpload
              title={lang.photos[currLang]}
              setImg={setImageData}
              img={imageData.length > 0 ? imageData[0] : null}
            />
          </div>
          <div style={{display: 'flex', flexDirection: 'column', alignSelf: 'center', width: '85%'}}>
            <button 
                className='button-process' 
                onClick={() => setPage(2)}
                style={isFieldsEmpty() ? {opacity: 0.8, cursor: 'not-allowed'} : {}}
                disabled={isFieldsEmpty()}
              >
                {lang.next[currLang]}
            </button>
           </div>
            
        </div>

      </div>
    </div>
    )
  }

  function secondPage(){
    return(
    <div className="MainContainer">
      <CustomModal modalOpen={emailModalOpen} className='emailModalContainer'>
				<div className='textsModalContainer'>
					<h1 className='PageModalTextContainer'>{lang.formRegister[currLang]}</h1>
				</div>
				<div className='textsModalContainer'>
					<p className='PageModalTextContainer'>{lang.formMessage[currLang]}</p>
				</div>
				<div className='modalInputText'>
					<CustomTextField
						placeholder={lang.email[currLang]}
						value={email}
						setValue={setEmail}
					/>
				</div>
        {emailWarning === true && <p style={{ color: 'red'}}>{lang.invalidEmail[currLang]}</p>}
				<div className='modalInputText'>	
					<CustomTextField
						placeholder={lang.message[currLang]}
						value={emailMessage}
						setValue={setEmailMessage}
					/>
				</div>
				<div className='modalInputText'>
					<CustomTextField
						placeholder={'Link'}
						value={formLink}
						disabled={true}
						//navigator.clipboard.writeText(selectedPlant.name)
					/>
					<FontAwesomeIcon 
						className={copy ? 'copyIconModalDisabled' : 'copyIconModal'}
						icon={copy ? faCheck : faCopy} 
						title="Copiar para área de transferência" 
						onClick={() => copy ? undefined : handleCopy()} 
					/>
				</div>
				
				<div className='modalButtonWrapper'>
					<AsyncButton
						active={loading || isModalFieldsEmpty()}
						text={lang.send[currLang]}
						className='PageModalButtonContainer'
						onClick={handleSendMail}
						loading={loading}
					/>

					<AsyncButton
						active={loading}
						text={lang.close[currLang]}
						className='PageModalButtonContainer'
						onClick={handleOpenNextModal}
						loading={loading}
					/>
				</div>
			</CustomModal>

      <CustomModalPage
				title={lang.processRegistered[currLang]}
				subtitle={lang.wantToRegisterEquipment[currLang]}
				yesPage='/Equipment?insert'
				noPage='/Home'
        yesText={lang.yes[currLang]}
				noText={lang.no[currLang]}
				modal={nextModalOpen}
			/>

      <div className='SideBarCompensation'/>

      <div className="CentralBlock" style={{height: '100vh'}}>
        <FlagSelector/>
        <Sidebar activePage='Register'/>
          
        <div className="RegisterMainContainer">
          <div className="iconContainer" style={{width: '100%'}}>
              <div
                className='iconLeft' 
                style={{alignSelf: 'center', width: '7%'}}
                onClick={handleBack}
                >
                
                <FontAwesomeIcon icon={faAngleLeft} title="Empresas já cadastradas"/> 
              </div>

              <div style={{display: 'flex', justifyContent: 'space-between', width: '85%'}}>
                <p className='clientText'>
                  {edit ? lang.editProcess[currLang] : lang.registerProcess[currLang]}
                </p>
              
                <div className='folderContainer' onClick={handleManagement}>
                  <FontAwesomeIcon icon={faFolder} title="Processos já cadastrados"/>        
                </div>
              </div>
          </div>          

          <div style={{width: '85%'}}>
            {showMap ? (
              <div form-container-process>
                <div style={{width: '85%'}}>
                  <text className='equipmentText2'>{lang.processMarker[currLang]}</text>
                </div>

                <div style={{padding: '.6rem'}}/>

                <div ref={mapContainer} style={{ width: '100%', height: '300px' }}/>

                <div style={{padding: '.6rem'}}/>

                <div style={{display: 'flex', width: '100%', height: '2rem', alignItems: "center"}}>
                  <FontAwesomeIcon 
                    icon={multipleLayers ? faCheckSquare : faSquare} 
                    style={{color: 'white', height: '70%', cursor: 'pointer'}} 
                    onClick={() => {
                      if(multipleLayers)
                        setNumFloors(1)

                      setMultipleLayers(prev => !prev)
                    }}
                  />

                  <div style={{padding: '5px'}}/>

                  <div style={{color: 'white'}}>
                    {lang.selectAFloor[currLang]}
                  </div>

                  <div style={{width: '3%'}}/>

                  <input 
                    type="number" 
                    value={numFloors} 
                    className='inputFloor'
                    disabled={!multipleLayers}
                    onChange={(event: any) => {
                      const text = event.target.value                      
                      const num  = Number(text)
                      
                      if(isNaN(num))
                        return 

                      if(num < 1)
                        return setNumFloors(1)
                      
                      if(num > 3)
                        return setNumFloors(3)
                      
                      setNumFloors(num)
                    }} 
                  />

                  <div style={{padding: '5px'}}/>
                </div>

                <button 
                  onClick={captureMap} 
                  className='button-process' 
                  style={isMarkerSet() ?  {width: '100%'} : {opacity: 0.8, cursor: 'not-allowed', width: '100%'}}
                  disabled={!isMarkerSet()}
                >
                  {lang.captureMap[currLang]}
                </button>

              </div>

            ) : (
              <div style={{width: '100%'}}>
                <div style={{width: '85%'}}>
                  <text className='equipmentText2'>{lang.processCapture[currLang]}</text>
                </div>

                <div style={{padding: '.6rem'}}/>
                <img src={capturedImageUrl} alt="Mapa Capturado" style={{ width: '100%', height: '100%' }}/>
                <div style={{padding: '.6rem'}}/>

                <AsyncButton
                  active={loading || isFieldsEmpty()}
                  text={edit ? lang.edit[currLang] : lang.register[currLang]}
                  className='button-process'
                  onClick={
                    edit 
                    ? 
                    async () => {setLoading(true); await handleEdit(); setLoading(false)}
                    :
                    async () => {setLoading(true); await handleSubmit(); setLoading(false)}
                  }
                  loading={loading}
                />
              </div>

            )}
          </div>
        </div>
        </div>
      </div>
    );
  }

  return (page == 1) ? firstPage() : secondPage()

}