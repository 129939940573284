import { useNavigate } from 'react-router-dom';
import { useState } from 'react';
import mapboxgl from 'mapbox-gl';

import { deleteJsonData, getStoredJson, useInitialCheck } from '../../../utils';
import { useGlobalContext } from '../../../contexts/GlobalContext';
import { getDataBaseCondition, getImagePaths } from '../../../utils/database';
import './styles.css';
import { FirstPage } from './pages/FirstPage';
import { SecondPage } from './pages/SecondPage';
import { ThirdPage } from './pages/ThirdPage';

mapboxgl.accessToken = "pk.eyJ1Ijoia2xhdXNzbWFyY2hpIiwiYSI6ImNsbGNsb245dzAyNXkzbm9iZzJ4emt2eWsifQ.HznsI_vVJ-lWe9swvbfT-Q";

export function EquipmentPage() {
	const [edit, setEdit] = useState(false)
	const [page, setPage] = useState(1)
	const [modalOpen, setModalOpen] = useState(false)

	const { globalOptions, setGlobalOptions } = useGlobalContext()
	const navigate = useNavigate();

	const [imageData, setImageData] = useState<any>([])
	const [fileData, setFileData] = useState<any>([])
	const [selectedEquipment, setSelectedEquipment] = useState<any>({})
	const [technicalData, setTechnicalData] = useState<any|null>(null);
	const [nextModalOpen, setNextModalOpen] = useState(false)
    const [emailModalOpen, setEmailModalOpen] = useState(false)

	const [numFloors, setNumFloors] = useState<number>(1)

	useInitialCheck(initialCheck, [window.location.href])

	function isValid(value: any) {
		return value && value !== undefined && value !== ''
	}

	async function initialCheck() {
		const currentUrl = window.location.href
		const isEdit = currentUrl.indexOf('?edit') != -1
		const isInsert = currentUrl.indexOf('?insert') != -1
		const isModal: boolean = currentUrl.indexOf('?modal') != -1
		setEdit(isEdit)
		let response, data
		if (isModal)
			handleOpenNextModal()

		if (!isEdit && !isInsert)
			return
	
		data = getStoredJson(isEdit ? 'edit' : 'insert')
  
		if (!data || data === undefined)
			return

		if (isValid(data.company)) {
			response = await getDataBaseCondition('companies', 'cnpj', data.company)
			data.company = response[0]
		}

		if (isValid(data.plant)) {
			response = await getDataBaseCondition('plants', 'plant_id', data.plant)
			data.plant = response[0]
		}

		if (isValid(data.process)) {
			response = await getDataBaseCondition('processes', 'process_id', data.process)
			data.process = response[0]
		}

		if (isValid(data.equipment_type)) {
			response = await getDataBaseCondition('equipment_type', 'id', data.equipment_type)
			data.equipment_type = response[0]
		}

		if (isValid(data.floor))
			setNumFloors(data.floor)
		
		if (isValid(data.img_path))
			setImageData(await getImagePaths(data.img_path))
		
		if (isValid(data.technical_data))
			setTechnicalData(JSON.parse(data.technical_data))

		setSelectedEquipment(data)
    deleteJsonData('insert')
    deleteJsonData('edit')
	}

	function handleManagement() {
		setGlobalOptions({ ...globalOptions, activeButton: 'Equipment' })
		navigate('/Management')
	}

	const handleOpenNextModal = () => {
		setEmailModalOpen(false)
		setNextModalOpen(true)
	};

	if (page === 1)
		return (
			<FirstPage
				edit={edit}
				handleManagement={handleManagement}
				imageData={imageData}
				fileData={fileData}
				modalOpen={modalOpen}
				navigate={navigate}
				selectedEquipment={selectedEquipment}
				setImageData={setImageData}
				setFileData={setFileData}
				setPage={setPage}
				setSelectedEquipment={setSelectedEquipment}
				setTechnicalData={setTechnicalData}
			/>
		)
	else if (page === 2)
		return (
			<SecondPage
				edit={edit}
				modalOpen={modalOpen}
				selectedEquipment={selectedEquipment}
				setPage={setPage}
				setTechnicalData={setTechnicalData}
				technicalData={technicalData}
			/>
		)
	else
		return (
			<ThirdPage
				edit={edit}
				handleManagement={handleManagement}
				imageData={imageData}
				fileData={fileData}
				modalOpen={modalOpen}
				navigate={navigate}
				numFloors={numFloors}
				page={page}
				selectedEquipment={selectedEquipment}
				setImageData={setImageData}
				setFileData={setFileData}
				setModalOpen={setEmailModalOpen}
				setNumFloors={setNumFloors}
				setPage={setPage}
				technicalData={technicalData}
			/>
		)
}
