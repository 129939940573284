import React, { ChangeEvent, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { faAngleLeft, faFolder } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { cpf } from "cpf-cnpj-validator";
import validator from 'validator';
import './styles.css';
import Sidebar from '../../../components/Sidebar';
import FlagSelector from '../../../components/FlagSelector';
import { RegisterAction, useRegister } from '../../../contexts/RegisterContext';
import { getStoredJson, saveJsonData, useInitialCheck } from '../../../utils';
import { useGlobalContext } from '../../../contexts/GlobalContext';
import { addDataBaseRow, editDatabaseByCondition, genUUID, getDataBaseCondition, getDatabaseRows, url } from '../../../utils/database';
import { CustomModalPage } from '../../../components/Modal'
import CustomTextField from '../../../components/CustomTextField'
import lang from './lang';
import AsyncButton from '../../../components/AsyncButton';
import CustomDropBox from '../../../components/CustomDropBox';


export function InspectorPage() {
	const { globalOptions, setGlobalOptions } = useGlobalContext()
	const currLang = globalOptions.language
	const navigate = useNavigate();

	const [edit, setEdit] = useState(false)
	const [cpfMsg, setCpfMsg] = useState(false)
	const [emailMsg, setEmailMsg] = useState(false)
	const [selectedInspector, setSelectedInspector] = useState<any>({})
	const [selectedService, setSelectedService] = useState<any>({})
	const [modalOpen, setModalOpen] = useState(false)
	const [loading, setLoading] = useState<boolean>(false)

	const services = [
		{ name: lang.corrosion[currLang], key: 'c' },
		{ name: lang.painting[currLang], key: 'p' },
		{ name: lang.nr13[currLang], key: 'nr13' },
	]

	useInitialCheck(initialCheck, [window.location.href])

	function initialCheck() {
		const currentUrl = window.location.href
		const isEdit = currentUrl.indexOf('?edit') != -1
		setEdit(isEdit)

		if (isEdit)
			setSelectedInspector(getStoredJson('edit'))
	}

	function handleCPFChange(text: string) {
		const rawValue = text.replace(/\D/g, '')
		let maskedValue = ''

		if (rawValue.length > 11)
			return;

		for (let i = 0; i < rawValue.length; i++) {
			if (i === 3 || i === 6)
				maskedValue += '.'

			if (i === 9)
				maskedValue += '-'

			maskedValue += rawValue[i]
		}

		setCpfMsg(false)
		setSelectedInspector({ ...selectedInspector, cpf: maskedValue })
	}

	function handleNameChange(text: string) {
		setSelectedInspector({ ...selectedInspector, name: text })
	}

	function handleEmailChange(text: string) {
		setEmailMsg(false)
		setSelectedInspector({ ...selectedInspector, email: text })
	}

	function handlePasswordChange(text: string) {
		setSelectedInspector({ ...selectedInspector, password: text })
	}

	function handleLevelChange(text: string) {
		setSelectedInspector({ ...selectedInspector, level: text })
	}

	function handleServiceChange(selectedOption: any) {
		let services = selectedOption.map((it: any) => it.key)

		setSelectedInspector({ ...selectedInspector, service: services })
	}

	async function handleEdit() {
		const edit = getStoredJson('edit')
		const rawValue = selectedInspector.cpf.replace(/\D/g, '');

		if (!validator.isEmail(selectedInspector.email))
			return setEmailMsg(true)

		if (!cpf.isValid(rawValue))
			return setCpfMsg(true)

		const newData = {
			name: selectedInspector.name,
			email: selectedInspector.email,
			password: selectedInspector.password,
			level: selectedInspector.level,
			cpf: selectedInspector.cpf,
			category: 'inspector',
			type: JSON.stringify(selectedInspector.service),
		}

		const response = await editDatabaseByCondition('users', 'email', edit.email, newData)

		if (response.status != 'success')
			return alert('erro ao editar dados')

		navigate(-1)
	}

	async function handleSubmit() {
		const rawValue = selectedInspector.cpf.replace(/\D/g, '');

		if (!validator.isEmail(selectedInspector.email))
			return setEmailMsg(true)

		if (!cpf.isValid(rawValue))
			return setCpfMsg(true)

		const inspectorData = {
			company_id: 1,
			name: selectedInspector.name,
			email: selectedInspector.email,
			password: selectedInspector.password,
			level: selectedInspector.level,
			cpf: selectedInspector.cpf,
			category: 'inspector',
			type: JSON.stringify(selectedInspector.service),
		}

		const checkValid = await getDataBaseCondition('users', 'name', inspectorData.name)

		if (checkValid.length > 0)
			return alert('usuário já cadastrado')

		const response = await fetch(`${url}createAccount`, {
			method: 'POST',
			body: JSON.stringify(inspectorData)
		})

		const result = await response.json()

		if (result.status != 'success')
			return alert('Erro ao se conectar com o banco de dados')


		setModalOpen(true)
	}

	function handleManagement() {
		setGlobalOptions({ ...globalOptions, activeButton: 'Inspector' })
		navigate('/Management')
	}

	function isFieldsEmpty() {
		const data = [
			selectedInspector?.cpf,
			selectedInspector?.email,
			selectedInspector?.name,
			selectedInspector?.level,
			selectedInspector?.password,
		]

		for (let value of data) {
			if (!value)
				return true

			if (value.trim().length == 0)
				return true
		}

		return false
	}

	return (
		<div className="MainContainer">
			<CustomModalPage
				title={lang.inspectorRegistered[currLang]}
				subtitle={lang.wantToRegisterClient[currLang]}
				yesPage='/Client'
				noPage='/Home'
				yesText={lang.yes[currLang]}
				noText={lang.no[currLang]}
				modal={modalOpen}
			/>

			<div className='SideBarCompensation' />

			<div className="CentralBlock" style={{ height: '100vh' }}>
				<FlagSelector />
				<Sidebar activePage='Register' />

				<div className="RegisterMainContainer">
					<div className="iconContainer" style={{ width: '100%' }}>
						<div
							className='iconLeft'
							style={{ alignSelf: 'center', width: '7%' }}
							onClick={() => navigate(-1)}
						>

							<FontAwesomeIcon icon={faAngleLeft} title="Empresas já cadastradas" />
						</div>

						<div style={{ display: 'flex', justifyContent: 'space-between', width: '85%' }}>
							<p className='clientText'>
								{edit ? lang.editInspector[currLang] : lang.registerInspector[currLang]}
							</p>

							<div className='folderContainer' onClick={handleManagement}>
								<FontAwesomeIcon icon={faFolder} title="Inspetores cadastrados" />
							</div>
						</div>
					</div>

					<div style={{ width: '85%' }}>
						<text className='equipmentText2'>{lang.inspectorInfo[currLang]}</text>
					</div>

					<div style={{ padding: '.6rem' }} />

					<div style={{ width: '85%' }}>
						<CustomTextField
							placeholder={lang.name[currLang]}
							value={selectedInspector.name ? selectedInspector.name : ''}
							setValue={handleNameChange}
						/>
					</div>

					<div style={{ padding: '.6rem' }} />

					<div style={{ width: '85%' }}>
						<CustomTextField
							placeholder={'Email'}
							value={selectedInspector.email}
							setValue={handleEmailChange}
						/>
						{emailMsg === true && <p style={{ color: 'red', marginBottom: '-0.5rem', paddingLeft: '0.5rem' }}>{lang.invalidEmail[currLang]}</p>}
					</div>

					<div style={{ padding: '.6rem' }} />

					<div style={{ width: '85%' }}>
						<CustomTextField
							placeholder={lang.password[currLang]}
							value={selectedInspector.password ? selectedInspector.password : ''}
							setValue={handlePasswordChange}
						/>
					</div>

					<div style={{ padding: '.6rem' }} />

					<div style={{ width: '85%' }}>
						<CustomTextField
							placeholder={lang.level[currLang]}
							value={selectedInspector.level ? selectedInspector.level : ''}
							setValue={handleLevelChange}
						/>
					</div>

					<div style={{ padding: '.6rem' }} />

					<div style={{ width: '85%' }}>
						<CustomTextField
							placeholder={lang.cpf[currLang]}
							value={selectedInspector.cpf ? selectedInspector.cpf : ''}
							setValue={handleCPFChange}
						/>

						{cpfMsg === true && <p style={{ color: 'red', paddingLeft: '0.5rem' }}>{lang.invalidCPF[currLang]}</p>}
					</div>

					<div style={{ padding: '.6rem' }} />

					<div style={{ display: 'flex', flexDirection: 'column', alignSelf: 'center', width: '85%' }}>
						<CustomDropBox
							options={services}
							value={selectedService ? selectedService.name : ''}
							setValue={handleServiceChange}
							placeholder={lang.services[currLang]}
							key='name'
							multiSelection={true}
						/>
					</div>

					<div style={{ display: 'flex', width: '100%', height: '3rem', justifyContent: 'center' }}>
						<AsyncButton
							active={loading || isFieldsEmpty()}
							text={edit ? lang.edit[currLang] : lang.register[currLang]}
							className='button-inspector'
							onClick={
								edit
									?
									async () => { setLoading(true); await handleEdit(); setLoading(false) }
									:
									async () => { setLoading(true); await handleSubmit(); setLoading(false) }
							}
							loading={loading}
						/>
					</div>

					<div style={{ padding: '.7rem' }} />
				</div>
			</div>
		</div>
	);
}