import React from 'react';
import { BrowserRouter, Routes, Route } from 'react-router-dom';

import { HomePage } from './pages/Home';
import { SignInPage } from './pages/Login';
import { InspectorPage } from './pages/Register/Inspector';
import { ClientPage } from './pages/Register/Client';
import { CompanyPage } from './pages/Register/Company';
import { PlantPage } from './pages/Register/Plant';
import { ProcessPage } from './pages/Register/Process';
import { EquipmentPage } from './pages/Register/Equipment';
import { ManagementPage } from './pages/Management';
import { SearchPage } from './pages/Explore/Search';
import { MapPage } from './pages/Explore/Map';
import { RequestPage } from './pages/Request';
import { RegionPage } from './pages/Explore/Region';
import { ExplorePage } from './pages/Explore/Explore';
import { CalendarPage } from './pages/Explore/Calendar';
import { ListPage } from './pages/Explore/List';
import { ReportsPage } from './pages/Reports/Reports';
import { RegisterPage } from './pages/Explore/Register';
import { Navigate } from 'react-router-dom';
import { deleteJsonData, getStoredJson } from './utils';
import DocumentPage from './pages/Explore/Document';
import { SelectReportPage } from './pages/Reports/SelectReport';
import { CorrosionPage } from './pages/Reports/Corrosion';
import { FormsPage } from './pages/Register/Forms';
import { Policy } from './pages/Presentation/Policy';
import { FormViewPage } from './pages/Explore/FormView';
import { DeleteAccount } from './pages/UsersRequest/DeleteAccount';

const ProtectedRoute = ({ children }: any) => {
    const user = getStoredJson('user')
    
    if(!user || !user.timestamp){
        deleteJsonData('user')
        return (<Navigate to="/"/>)
    }
    
    if (Date.now() - user.timestamp > 200*60*1000){ // desloga após 200 min
        deleteJsonData('user')
        return (<Navigate to="/"/>)
    }
    
    return children;
}

function AppRoutes() {
    return (
        <BrowserRouter>
            <Routes>
                <Route path="/" element={<SignInPage/>} />
                <Route path="/Home" element={<ProtectedRoute><HomePage /></ProtectedRoute>} />
                <Route path="/Inspector" element={<ProtectedRoute><InspectorPage /></ProtectedRoute>} />
                <Route path="/Client" element={<ProtectedRoute><ClientPage /></ProtectedRoute>} />
                <Route path="/Company" element={<ProtectedRoute><CompanyPage /></ProtectedRoute>} />
                <Route path="/Plant" element={<ProtectedRoute><PlantPage /></ProtectedRoute>} />
                <Route path="/Process" element={<ProtectedRoute><ProcessPage /></ProtectedRoute>} />
                <Route path="/Equipment" element={<ProtectedRoute><EquipmentPage /></ProtectedRoute>} />
                <Route path="/Management" element={<ProtectedRoute><ManagementPage /></ProtectedRoute>} />
                <Route path="/Search" element={<ProtectedRoute><SearchPage /></ProtectedRoute>} />
                <Route path="/Map" element={<ProtectedRoute><MapPage /></ProtectedRoute>} />
                <Route path="/Request" element={<ProtectedRoute><RequestPage /></ProtectedRoute>} />
                <Route path="/Region" element={<ProtectedRoute><RegionPage /></ProtectedRoute>} />
                <Route path="/Explore" element={<ProtectedRoute><ExplorePage /></ProtectedRoute>} />
                <Route path="/Calendar" element={<ProtectedRoute><CalendarPage /></ProtectedRoute>} />
                <Route path="/List" element={<ProtectedRoute><ListPage /></ProtectedRoute>} />
                <Route path="/Reports" element={<ProtectedRoute><ReportsPage /></ProtectedRoute>} />
                <Route path="/Document" element={<ProtectedRoute><DocumentPage/></ProtectedRoute>} />
                <Route path="/SelectReport" element={<ProtectedRoute><SelectReportPage/></ProtectedRoute>}/>
                <Route path="/Register" element={<ProtectedRoute><RegisterPage/></ProtectedRoute>} />
                <Route path="/Corrosion" element={<ProtectedRoute><CorrosionPage/></ProtectedRoute>} />
                <Route path="/Forms" element={<FormsPage/>} />
                <Route path='/Policy' element={<Policy/>}/>
                <Route path="/FormView" element={<ProtectedRoute><FormViewPage/></ProtectedRoute>} />
                <Route path="/DeleteAccount" element={<DeleteAccount/>}/>
            </Routes>
        </BrowserRouter>
    );
}

export default AppRoutes;
